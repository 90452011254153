import React, { ReactElement, useRef } from 'react';
import * as Styled from './styles';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';

interface SignupProps {
  subTitle: ReactElement | string;
  title: ReactElement | string;
}

export const TrialSignup = ({ subTitle, title }: SignupProps): ReactElement => {
  const signupRef = useRef(null);
  const { isVisible } = useVisibilityDetection(signupRef);

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper isVisible={isVisible} ref={signupRef}>
        <Styled.Heading>
          <Styled.Title>{title}</Styled.Title>
          <Styled.SubTitle>{subTitle}</Styled.SubTitle>
        </Styled.Heading>
        <Styled.Section>
          <Styled.Button href="https://checkout.sketchwow.com?add-to-cart=2064">TRY IT FREE</Styled.Button>
        </Styled.Section>
        <Styled.Footer>
          <p>
            SketchWow works flawlessly on <Styled.Doodle>Windows & Macs.</Styled.Doodle>
          </p>
        </Styled.Footer>
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
